import React from "react";
import Slider from "react-slick";
import ProductCard from "../../components/card/ProductCard";
import SquareButton from "../../components/button/SquareButton";
import RoundedButton from "../../components/button/RoundedButton";
import { NextButton, PrevButton } from "../../components/button/SliderButton";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  nextArrow: <PrevButton />,
  prevArrow: <NextButton />,
  speed: 3000,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const CurrentProjects = ({ projects }) => {
  return (
    <div className="pt-14 wrapper bg-white relative z-0">
      <div className="absolute -top-[20px] left-1/2 -translate-x-[50%] z-10">
        <SquareButton text="CURRENT PROJECTS" />
      </div>
      {projects?.length <= 3 ? (
        <div className="grid sm:grid-cols-2 md:grid-cols-3 pt-16">
          {projects.map((project) => (
            <ProductCard key={project.id} project={project} isSold={false} />
          ))}
        </div>
      ) : (
        <Slider {...settings}>
          {projects?.map((project) => (
            <ProductCard key={project.id} project={project} isSold={false} />
          ))}
        </Slider>
      )}

      <div className="pt-7 pb-10 flex justify-center">
        <RoundedButton text="VIEW ALL CURRENT PROJECTS" />
      </div>
    </div>
  );
};

export default CurrentProjects;
