import React from "react";

const MapTwo = () => {
  return (
    <div>
      <div className="mt-5">
        <h3 className="flex justify-center font-medium text-3xl my-4  ">
          Location
        </h3>
      </div>
      <div className="bg-[#e8e7e3]">
        <div className="py-12 wrapper">
          <div
            style={{
              position: "relative",
              textAlign: "right",
              width: "100%",
              height: "500px",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                background: "none",
                width: "100%",
                height: "500px",
                borderRadius: "20px",
              }}
            >
              <iframe
                style={{
                  height: "500px",
                  borderRadius: "20px",
                  visibility: "visible",
                }}
                title="title"
                width="100%"
                src="https://maps.google.com/maps?width=1200&amp;height=600&amp;hl=en&amp;q= 122 Archibald Road Kelston Auckland &amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapTwo;
