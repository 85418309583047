import React from "react";
import CallUsBtn from "../../components/button/CallUsBtn";
import EnquireBtn from "../../components/button/EnquireBtn";
import Layout from "../../components/layout/Layout";
import PropertyHeader from "./PropertyHeader";
import Description from "./Description";
import PriceStarts from "./PriceStarts";
import { useParams } from "react-router-dom";
import currentProjectData from "../../data/currentProjectData";
import GalleryModal from "../../components/modal/GalleryModal";
import MapTwo from "../../components/map/MapTwo";
import MapOne from "../../components/map/MapOne";
import Units from "./Units";
import allUnits from "../../data/units";

const Property = () => {
  const { propertyId } = useParams();

  const property = currentProjectData.find((pro) => {
    return pro.id.toString() === propertyId.toString();
  });

  const {
    id,
    address,
    priceStart,
    expectedHandOverDate,
    initialDeposit,
    descriptionTitle,
    description,
    pdf,
    gallery,
  } = property || {};

  const { cardImage } = property.images || {};

  const filterdUnits = allUnits.filter(
    (unit) => unit.projectId.toString() === propertyId.toString()
  );

  return (
    <Layout>
      <PropertyHeader address={address} />
      <div className="my-8">
        <img className="w-full h-full lg:h-[600px] " src={cardImage} alt="" />
      </div>
      <div className="wrapper my-24">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-12 lg:gap-x-20">
          <div className="col-span-1 lg:col-span-7">
            <h3 className="text-2xl font-semibold mb-5">{descriptionTitle}</h3>
            <Description description={description} />
          </div>

          <div className="col-span-1 lg:col-span-5">
            <PriceStarts
              priceStarts={priceStart}
              expectedHandOverDate={expectedHandOverDate}
              initialDeposit={initialDeposit}
            />
            <div className="mt-3">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={pdf}
                className="bg-gray-200 flex justify-between py-2 px-20 items-center rounded"
              >
                <h3>View Info Pack</h3>
                <img className="h-4" src="/images/Vector.png" alt="" />
              </a>
              <GalleryModal gallery={gallery} />
              <a
                href="#footer"
                className="mt-3 bg-gray-200 flex justify-between py-2 px-20 items-center rounded"
              >
                <h3>Contact Info</h3>
                <img className="h-4 " src="/images/Vector.png" alt="" />
              </a>
            </div>
            <div className="mt-8 flex gap-x-3">
              <CallUsBtn />
              <EnquireBtn />
            </div>
          </div>
        </div>
      </div>
      {id <= 1 ? <MapOne /> : <MapTwo />}
      <Units units={filterdUnits} />
    </Layout>
  );
};

export default Property;
