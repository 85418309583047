import React from "react";

const Deposit = ({ initialDeposit }) => {
  return (
    <div className="absolute top-[40%] right-10 w-32 h-32 rounded-full bg-brown-custom-100 flex items-center justify-center shadow-xl">
      <div className="">
        <p className="text-[16px] font-medium text-white">Initial deposit</p>
        <p className="text-[20px] text-center text-gray-100">
          {initialDeposit}%
        </p>
        <p className="text-sm font-normal text-white text-center">Only</p>
      </div>
    </div>
  );
};

export default Deposit;
