import React from "react";
import UnitImage from "../../components/units/UnitImage";
import UnitHeader from "../../components/units/UnitHeader";
import UnitDetails from "../../components/units/UnitDetails";

const Units = ({ units }) => {
  return (
    <div>
      <div>
        <h3 className="flex justify-center font-medium text-3xl mt-8 mb-7 ">
          Unit Details
        </h3>
      </div>
      <div className="wrapper my-24">
        {units.map((unit, index) => {
          const {
            title,
            unit: unitTitle,
            bedRoom,
            bathRoom,
            sofa,
            car,
            images,
            unitsHeader,
            unitsBody,
          } = unit;
          return (
            <div className="mb-24 grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-x-10 md:gap-y-5 lg:gap-x-16 xl:gap-x-20">
              <div
                className={`col-span-1 ${
                  index % 2 === 0 ? "order-1" : "order-2"
                }`}
              >
                <UnitHeader
                  title={title}
                  unitTitle={unitTitle}
                  bedRoom={bedRoom}
                  bathRoom={bathRoom}
                  sofa={sofa}
                  car={car}
                />
              </div>
              <div
                className={`col-span-1 ${
                  index % 2 === 0 ? "order-2" : "order-1"
                }`}
              >
                <UnitImage
                  image={images.bedroomImage.image}
                  type={images.bedroomImage.type}
                />
              </div>

              <div
                className={`col-span-1 ${
                  index % 2 === 0 ? "order-3" : "order-4"
                }`}
              >
                <UnitDetails unitsHeader={unitsHeader} unitsBody={unitsBody} />
              </div>
              <div
                className={`col-span-1 ${
                  index % 2 === 0 ? "order-4" : "order-3"
                }`}
              >
                <UnitImage
                  image={images.bathroomImage.image}
                  type={images.bathroomImage.type}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Units;
