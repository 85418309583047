import React from "react";
import { FaBath, FaBed, FaCarAlt } from "react-icons/fa";
import { TbSofa } from "react-icons/tb";

const UnitHeader = ({
  orderIndex,
  title,
  unitTitle,
  bedRoom,
  bathRoom,
  sofa,
  car,
}) => {
  return (
    <>
      <h3 className="text-2xl font-semibold text-gray-800 leading-6">
        {title}
      </h3>
      <p className="mt-2 font-normal text-gray-800 text-xl">Unit {unitTitle}</p>
      <div className="mt-5 flex gap-x-12 items-center text-3xl text-center lg:text-4xl">
        <div>
          <span>
            <FaBed />
          </span>
          <span className="text-brown-custom-100">{bedRoom}</span>
        </div>
        <div>
          <span>
            <FaBath />
          </span>
          <span className="text-brown-custom-100">{bathRoom}</span>
        </div>
        <div>
          <span>
            <TbSofa />
          </span>
          <span className="text-brown-custom-100">{sofa}</span>
        </div>
        <div>
          <span>
            <FaCarAlt />
          </span>
          <span className="text-brown-custom-100">{car}</span>
        </div>
      </div>
    </>
  );
};

export default UnitHeader;
