import React from "react";

const CallUsBtn = () => {
  return (
    <button
      type="button"
      className="w-full py-3 px-8 text-[16px] font-normal text-white focus:outline-none bg-[#966919] rounded-full border border-gray-200 hover:text-gray-800 "
    >
      <a href="tel:+64212254058"> Call Us Now!</a>
    </button>
  );
};

export default CallUsBtn;
