const navbarData = [
  {
    title: "Current Projects",
    link: "/",
  },
  // {
  //   title: "Upcoming Projects",
  //   link: "/",
  // },
  // {
  //   title: "Completed Projects",
  //   link: "/",
  // },
  {
    title: "About Us",
    link: "#footer",
  },
  {
    title: "Contact Us",
    link: "#footer",
  },
];

export default navbarData;
