const currentProjectData = [
  {
    id: 1,
    address: {
      roadNo: "18 Sylvan Crescent",
      suburb: "Te Atatu South",
      city: "Auckland",
    },
    type: ["Residential"],
    totalPeople: 4,
    images: {
      cardImage: "https://i.ibb.co/gmzWnfN/Exterior-1-1.jpg",
    },
    priceStart: [
      { type: "one bedroom unit", price: 585000 },
      { type: "three bedroom unit", price: 995000 },
    ],
    expectedHandOverDate: "15th Nov - 15th Dec 2023",
    initialDeposit: 5,
    pdf: "https://drive.google.com/file/d/1lPuPXR5PnnCIqZCAjbw1V0iiXmVR9O4c/view?usp=sharing",
    descriptionTitle:
      "Find Your Perfect Fit: Discover One to Five-Bedroom Homes in Our Spacious Townhouse Community!",
    description: [
      "These newly built townhouses are an excellent option for those looking for comfortable, low-maintenance homes in a great location.",
      "These properties boast a range of features designed to enhance your comfort and convenience. With spacious living areas, modern amenities, and stylish finishes, you'll feel right at home in these cosy and welcoming spaces.",
      "Overall, these are an excellent choice for anyone looking for a comfortable, convenient, and affordable living option in a prime location. Whether you're a busy professional or a family looking for a place to call home, these properties are sure to exceed your expectations.",
    ],
    gallery: [
      "https://i.ibb.co/NtPpz8n/Exterior-1-2.jpg",
      "https://i.ibb.co/Jq8h7gg/Exterior-2.jpg",
      "https://i.ibb.co/YQtJjNr/Interior-1.jpg",
      "https://i.ibb.co/4RGPKbk/Interior-2.jpg",
      "https://i.ibb.co/zVTQH5S/Interior-3.jpg",
      "https://i.ibb.co/WszStD2/Exterior-1-3.jpg",
      "https://i.ibb.co/3zpNgZd/Interior-1-1.jpg",
      "https://i.ibb.co/rdqBJYP/Interior-2-1.jpg",
      "https://i.ibb.co/R7B5jHh/Interior-3-1.jpg",
    ],
  },
  {
    id: 2,
    address: {
      roadNo: "122 Archibald Road",
      suburb: "Kelston",
      city: "Auckland",
    },
    type: ["Residential"],
    totalPeople: 4,
    images: {
      cardImage: "https://i.ibb.co/WPkVh6N/Exterior-1.jpg",
    },
    priceStart: [
      { type: "One bedroom unit", price: 529000 },
      { type: "Three bedroom unit", price: 895000 },
    ],
    expectedHandOverDate: "15th Oct - 15th Nov 2023",
    initialDeposit: 5,
    pdf: "https://drive.google.com/file/d/1hd4lalBFKM_2xUyIMqOV2ZD-XQwkh2a8/view?usp=sharing",
    descriptionTitle:
      "Welcome to our newly built multi unit residential complex-",
    description: [
      "Offering a variety of modern homes ranging from cosy 1-bedroom units to spacious 3-bedroom houses. Located in a prime location, our complex is nestled in the heart of a vibrant community and surrounded by an array of amenities. Whether you're a young professional, a growing family, or anyone in between, our multi-unit residential complex has something for everyone.",
      "Come experience the convenience and comfort of modern living in a vibrant community today.",
      "The property is primely located in a vibrant community. Families will love the proximity to top-rated schools, while nature lovers will appreciate the nearby parks and green spaces. Our complex is also conveniently located close to shopping centres, making it easy to run errands or indulge in some retail therapy. And if you're looking for a new outdoor hangout spot, the recently constructed park nearby offers a great place to relax and enjoy some fresh air",
    ],
    gallery: [
      "https://i.ibb.co/VBGbmXZ/Exterior-1.jpg",
      "https://i.ibb.co/w0nfDFs/Exterior-2.jpg",
      "https://i.ibb.co/s5jc442/Interior-1.jpg",
      "https://i.ibb.co/r0j3Vsp/Interior-2.jpg",
      "https://i.ibb.co/NrQmGDG/Interior-3.jpg",
      "https://i.ibb.co/BNXT1JJ/Exterior-1.jpg",
      "https://i.ibb.co/QmzZNSb/Interior-1.jpg",
      "https://i.ibb.co/jGd5qFH/Interior-2.jpg",
      "https://i.ibb.co/BcJ0c5k/Interior-3.jpg",
    ],
  },
];

export default currentProjectData;
