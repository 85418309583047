import React from "react";

const UnitImage = ({ image, type }) => {
  return (
    <div className="w-full h-72 relative">
      <span className="absolute right-5 bottom-5 text-white">{type}</span>
      <img src={image} alt={type} className="w-full h-full" />
    </div>
  );
};

export default UnitImage;
