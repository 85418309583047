import React from "react";

const RoundedButton = ({ text }) => {
  return (
    <button
      type="button"
      className="py-2 px-8 text-sm font-medium text-black-custom-100 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-brown-custom-100"
    >
      {text}
    </button>
  );
};

export default RoundedButton;
