import UnitRow from "./UnitRow";

const UnitDetails = ({ unitsHeader, unitsBody }) => {
  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left">
        <thead className="text-sm bg-white border-b-2 border-brown-custom-100">
          <tr>
            {unitsHeader.map((header) => (
              <th key={header} scope="col" className="py-3">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border-b-2 border-brown-custom-100">
          {unitsBody.map((body) => {
            const {
              unit,
              groundFloor,
              firstFloor,
              secondFloor,
              totalFloor,
              floorArea,
              deckArea,
            } = body || {};
            return (
              <tr key={body.unit} className="bg-white text-sm">
                {unit && (
                  <th scope="row" className="py-3 font-medium">
                    {unit}
                  </th>
                )}
                {groundFloor && <UnitRow unit={groundFloor} />}
                {firstFloor && <UnitRow unit={firstFloor} />}
                {secondFloor && <UnitRow unit={secondFloor} />}
                {floorArea && <UnitRow unit={floorArea} />}
                {deckArea && <UnitRow unit={deckArea} />}
                {totalFloor && <UnitRow unit={totalFloor} />}

                {/* {groundFloor && (
                    <th scope="row" className="py-3 font-medium">
                      {groundFloor} &#13217;
                    </th>
                  )}
                  {firstFloor && (
                    <th scope="row" className="py-3 font-medium">
                      {firstFloor} &#13217;
                    </th>
                  )}
                  {secondFloor && (
                    <th scope="row" className="py-3 font-medium">
                      {secondFloor} &#13217;
                    </th>
                  )}
                  {floorArea && (
                    <th scope="row" className="py-3 font-medium">
                      {floorArea} &#13217;
                    </th>
                  )}
                  {deckArea && (
                    <th scope="row" className="py-3 font-medium">
                      {deckArea} &#13217;
                    </th>
                  )}
                  {totalFloor && (
                    <th scope="row" className="py-3 font-medium">
                      {totalFloor} &#13217;
                    </th>
                  )} */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UnitDetails;
