import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};

const GalleryModal = ({ gallery }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <button
        type="button"
        className="mt-3 w-full bg-gray-200 flex justify-between py-2 px-20 items-center rounded cursor-pointer"
        data-hs-overlay="#hs-scroll-inside-viewport-modal"
        onClick={openModal}
      >
        <h3>Gallery</h3>
        <img className="h-4 " src="/images/Vector.png" alt="" />
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 bg-backdrop" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="font-bold text-gray-800 leading-6"
                  >
                    Image Gallery
                  </Dialog.Title>
                  <div className="mt-3 overflow-hidden">
                    <Slider {...settings}>
                      {gallery?.length > 0 &&
                        gallery.map((img) => (
                          <div className="w-full h-80 cursor-pointer" key={img}>
                            <img
                              src={img}
                              alt="..."
                              className="w-full h-full rounded-md"
                            />
                          </div>
                        ))}
                    </Slider>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default GalleryModal;
