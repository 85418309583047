const locationData = [
  "Auckland",
  "Wellington",
  "Christchurch",
  "Manukau City",
  "Hamilton",
  "Dunedin",
];

export default locationData;
