import React from "react";
import Deposit from "./Deposit";

const PriceStarts = ({ priceStarts, expectedHandOverDate, initialDeposit }) => {
  return (
    <div className="bg-gray-200 p-5 relative rounded">
      <Deposit initialDeposit={initialDeposit} />
      <h3 className="text-4xl font-normal mb-5 leading-tight">
        New Built on
        <br /> <strong>SALE</strong>
      </h3>
      <h3 className="text-lg font-poppins font-normal mb-3">
        Price Starting from:
      </h3>
      {priceStarts.map((priceStart) => (
        <div className="mt-2" key={priceStart.type}>
          <h3 className="text-lg italic">
            $ {priceStart.price.toLocaleString("en-US")}
          </h3>
          <p className="text-sm italic text-gray-500">({priceStart.type})</p>
        </div>
      ))}
      <div className="mt-5">
        <h3 className="text-lg font-normal">Expected handover date:</h3>
        <p className="text-sm font-normal italic text-gray-500">
          {expectedHandOverDate}
        </p>
      </div>
    </div>
  );
};

export default PriceStarts;
