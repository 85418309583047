import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import homeData from "../../data/homeData";
import locationData from "../../data/locationData";

const EnquireBtn = () => {
  const form = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_t2qoh3t",
        "template_mwwhacb",
        form.current,
        "aWQXuszIw1JviMOQO"
      )
      .then(
        (res) => {
          if (res.status === 200) {
            e.target.reset();
            setMessage("Email sent successfully!");
            setTimeout(() => {
              closeModal();
            }, 2000);
          }
        },
        (error) => {
          setMessage(error.message);
        }
      );
  };

  return (
    <>
      <button
        type="button"
        className="w-full py-3 px-auto md:px-8 text-[15px] font-normal text-black focus:outline-none border-[#966919] rounded-full border  hover:bg-gray-100"
        data-hs-overlay="#hs-scroll-inside-viewport-modal"
        onClick={openModal}
      >
        Enquire for More Info!
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 bg-backdrop" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-md bg-gray-200 p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-3 overflow-hidden">
                    <div className="text-center">
                      <h3 className="font-bold text-3xl text-gray-800 leading-6">
                        Talk To Us
                      </h3>
                      <p className="mt-5 text-sm">
                        Wherever you are in property journey, we'd love to hear
                        from you
                      </p>
                    </div>
                    <form className="mt-5" ref={form} onSubmit={sendEmail}>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <input
                          type="text"
                          className="border-0 outline-none bg-gray-50 text-gray-800 px-4 py-2 rounded-md font-normal"
                          placeholder="First Name"
                          name="first_name"
                          required
                        />
                        <input
                          type="text"
                          className="border-0 outline-none bg-gray-50 text-gray-800 px-4 py-2 rounded-md font-normal"
                          placeholder="Last Name"
                          name="last_name"
                          required
                        />
                        <input
                          type="email"
                          className="border-0 outline-none bg-gray-50 text-gray-800 px-4 py-2 rounded-md font-normal"
                          placeholder="Email"
                          name="user_email"
                          required
                        />
                        <input
                          type="tel"
                          className="border-0 outline-none bg-gray-50 text-gray-800 px-4 py-2 rounded-md font-normal"
                          placeholder="Phone Number"
                          name="user_phone_number"
                          required
                        />
                        <select
                          className="border-0 outline-none bg-gray-50 text-gray-800 px-4 py-2 rounded-md font-normal"
                          name="purchase_type"
                          required="required"
                        >
                          <option hidden value="">
                            Purchase Type
                          </option>
                          {homeData.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select
                          className="border-0 outline-none bg-gray-50 text-gray-800 px-4 py-2 rounded-md font-normal"
                          name="interested_location"
                          required="required"
                        >
                          <option hidden value="">
                            Which location are you interested in
                          </option>
                          {locationData.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                      <textarea
                        className="mt-4 border-0 outline-none bg-gray-50 text-gray-800 px-4 py-2 rounded-md font-normal w-full min-h-[5rem]"
                        placeholder="Your message..."
                        name="message"
                        required
                      ></textarea>
                      <div className="mt-8 flex justify-center">
                        <button
                          type="submit"
                          className="border border-red-500 outline-none bg-gray-50 text-gray-800 px-6 py-1.5 rounded-full font-medium"
                        >
                          Submit
                        </button>
                      </div>
                      {message && (
                        <p className="mt-4 text-center text-red-500 text-sm">
                          {message}
                        </p>
                      )}
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EnquireBtn;
