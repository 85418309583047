import React from "react";
import { FiMail, FiPhoneCall } from "react-icons/fi";
import { Link } from "react-router-dom";

const aboutUsData = [
  {
    title: "Our Story",
    link: "/",
  },
  {
    title: "Meet Our Team",
    link: "/",
  },
  {
    title: "Our Services",
    link: "/",
  },
];

const projectsData = [
  {
    title: "Current Projects",
    link: "/",
  },
  {
    title: "Completed Projects",
    link: "/",
  },
  {
    title: "Upcoming Projects",
    link: "/",
  },
];

const Footer = () => {
  return (
    <section id="footer" className="bg-black-custom-100 text-white">
      <div className="py-8 wrapper text-center md:text-left">
        <h1 className="text-2xl font-light md:text-3xl">
          Everyone Deserves The <br />
          Opportunity of Home
        </h1>
        <div className="my-16 grid grid-cols-1 gap-y-8 md:grid-cols-3">
          <div className="col-span-1">
            <h5 className="text-[15px]">About Us</h5>
            <ul>
              {aboutUsData.map((about) => (
                <li
                  key={about.title}
                  className="my-4 text-sm text-gray-400 font-medium hover:underline"
                >
                  <Link to={about.link}>{about.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-1">
            <h5 className="text-[15px]">Our Projects</h5>
            <ul>
              {projectsData.map((project) => (
                <li
                  key={project.title}
                  className="my-4 text-sm text-gray-400 font-medium hover:underline"
                >
                  <Link to={project.link}>{project.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-1">
            <h5 className="text-[15px]">Contact Us</h5>
            <ul className="grid justify-center lg:justify-start">
              <li className="my-4 text-sm text-gray-400 font-medium hover:underline">
                <a
                  href="tel:+64212254058"
                  className="flex items-center gap-x-2"
                >
                  <FiPhoneCall />
                  0212254058
                </a>
              </li>
              <li className="my-2 text-sm text-gray-400 font-medium hover:underline">
                <a
                  href="mailto:properties@eight-o.co.nz"
                  className="flex items-center gap-x-2"
                >
                  <FiMail />
                  properties@eight-o.co.nz
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
