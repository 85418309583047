import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

export const PrevButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="absolute top-[40%] -right-[16px] text-[30px] z-40 bg-black-custom-100 text-white"
    >
      <FiChevronRight />
    </button>
  );
};

export const NextButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="absolute top-[40%] -left-[14px] text-[30px] z-40 bg-black-custom-100 text-white"
    >
      <FiChevronLeft />
    </button>
  );
};
