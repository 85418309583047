import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ project, isSold }) => {
  const { id, address, images, totalPeople } = project || {};
  const { roadNo, suburb, city } = address || {};
  const { cardImage } = images || {};
  return (
    <Link to={`/property/${id}`}>
      <div className="mx-0 sm:mx-2 lg:mx-4 xl:mx-6">
        <div
          className="w-full h-60 bg-no-repeat bg-center bg-cover shadow-3xl"
          style={{
            backgroundImage: `url('${cardImage}')`,
          }}
        ></div>
        <div className="mt-4 mb-2 w-full px-5 py-1.5 bg-gray-custom-100 shadow">
          <p className="capitalize text-[16px] font-medium leading-[1.1]">
            {roadNo}, {suburb}
          </p>
          <p className="capitalize text-[16px] font-medium leading-[1.1]">
            {city}
          </p>
        </div>
        {isSold ? (
          <small className="mb-2 px-5 text-[12px] font-normal text-brown-custom-100">
            Sold Out
          </small>
        ) : totalPeople > 0 ? (
          <small className="mb-2 px-5 text-[12px] font-normal text-brown-custom-100">
            Available*
          </small>
        ) : null}
      </div>
    </Link>
  );
};

export default ProductCard;
