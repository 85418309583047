import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Property from "./pages/property/Property";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/property/:propertyId" element={<Property />} />
    </Routes>
  );
}

export default App;
