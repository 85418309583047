import React, { useState } from "react";
import Dropdown from "react-dropdown";
import homeData from "../../data/homeData";
import locationData from "../../data/locationData";

import "react-dropdown/style.css";

const Hero = ({ onClick }) => {
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");

  return (
    <div
      className="h-[30vh] relative bg-no-repeat md:h-auto"
      style={{
        backgroundImage: "url('/images/background/hero.jpeg')",
        backgroundPosition: "right top",
      }}
    >
      <div className="relative h-full">
        <img
          className="hidden md:block h-[90.5%]"
          src="/images/background/TopPicHolder.png"
          alt=""
        />
        <div className="wrapper absolute top-[40%] left-1/2 -translate-x-1/2 -translate-y-1/2 z-[1]">
          <h1 className="text-xl font-light uppercase text-white tracking-widest text-center md:text-3xl">
            Your future home is here!!
          </h1>
          <div className="mt-4 grid grid-cols-12 md:w-12/12 md:w-10/12 md:mx-auto lg:w-7/12 ">
            <div className="col-span-9 py-2 bg-white grid grid-cols-12 items-center rounded-l-full md:py-2">
              <div className="hidden col-span-5 text-center text-[10px] uppercase font-medium border-r border-brown-custom-100 text-black-custom-100 md:block md:text-[12px] md:col-span-4 ">
                I'm looking for
              </div>
              <div className="col-span-9 pl-3 text-center text-[10px] uppercase font-medium border-r border-brown-custom-100 md:text-[12px] md:pl-0 md:col-span-5">
                <Dropdown
                  options={homeData}
                  placeholder="My first home"
                  onChange={(option) => setType(option.value)}
                />
              </div>
              <div className="col-span-3 text-center text-[10px] uppercase font-medium md:text-[12px] md:col-span-3 ">
                <Dropdown
                  options={locationData}
                  placeholder="Location"
                  onChange={(option) => setLocation(option.value)}
                />
              </div>
            </div>
            <div className="col-span-3 bg-gray-custom-200 rounded-r-full">
              <button
                className="w-full h-full text-[10px] uppercase font-medium md:text-[12px]"
                onClick={() => onClick({ location, type })}
              >
                Find Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

// <div className="wrapper h-full flex flex-col justify-center">
//         <h1 className="text-xl font-light uppercase text-white tracking-widest text-center md:text-3xl">
//           Your future home is here !
//         </h1>
//         <div className="mt-4 grid grid-cols-12 md:w-12/12 md:w-10/12 md:mx-auto lg:w-7/12">
//           <div className="col-span-9 py-2 bg-white grid grid-cols-12 items-center rounded-l-full md:py-4 lg:px-4">
//             <div className="col-span-5 text-center text-[10px] uppercase font-medium border-r border-brown-custom-100 text-black-custom-100  md:text-[12px] md:px-4">
//               I'm locking for
//             </div>
//             <div className="col-span-4 text-center text-[10px] uppercase font-medium border-r border-brown-custom-100 md:text-[12px] md:px-4">
//               My first home
//             </div>
//             <div className="col-span-3 text-center text-[10px] uppercase font-medium md:text-[12px] md:px-4">
//               Location
//             </div>
//           </div>
//           <div className="col-span-3 bg-gray-custom-200 rounded-r-full">
//             <button className="w-full h-full text-[10px] uppercase font-medium md:text-[12px] md:px-4">
//               Find Home
//             </button>
//           </div>
//         </div>
//       </div>
