import React, { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import navbarData from "../../data/navbar";

const Header = () => {
  const [open, setOpen] = useState(false);

  const openHandler = () => {
    setOpen(!open);
  };

  return (
    <div className="bg-white">
      <div className="wrapper py-1">
        <div className="flex justify-between items-center">
          <a href="/">
            <img className="w-[200px] h-[90px]" src="/eightoLogo.png" alt="" />
          </a>
          <span className="block cursor-pointer md:hidden">
            <FiMenu size={44} onClick={openHandler} />
          </span>
        </div>
      </div>
      <div className="hidden w-full bg-gray-custom-200 md:block">
        <ul className="wrapper flex justify-end gap-x-12">
          {navbarData.map((nav) => (
            <li
              key={nav.title}
              className="py-1 text-[12px] uppercase text-white hover:underline"
            >
              <a className="transition-all duration-500" href={nav.link}>
                {nav.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      {open && (
        <div className="block py-5 w-full bg-gray-custom-200 md:hidden">
          <ul className="wrapper">
            {navbarData.map((navbar, index) => (
              <li key={index}>
                <Link
                  href={navbar.link}
                  className="px-2 py-1 text-sm block font-medium rounded text-white hover:bg-gray-100 hover:text-black-custom-100"
                >
                  {navbar.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
