import React from "react";

const UnitRow = ({ unit }) => {
  if (typeof unit !== "number") {
    return (
      <th scope="row" className="py-3 font-medium">
        {unit}
      </th>
    );
  }
  return (
    <th scope="row" className="py-3 font-medium">
      {unit} &#13217;
    </th>
  );
};

export default UnitRow;
