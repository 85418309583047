import React from "react";

const SquareButton = ({ text }) => {
  return (
    <button className="px-5 py-2 text-center font-medium text-sm text-white bg-brown-custom-100 focus:outline-none cursor-default shadow-3xl">
      {text}
    </button>
  );
};

export default SquareButton;
