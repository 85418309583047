import React, { useState } from "react";
import currentProjectData from "../../data/currentProjectData";
// import completedProjectData from "../../data/completedProjectData";
import Layout from "../../components/layout/Layout";
import TalkUs from "../../components/talkUs/TalkUs";
import CurrentProjects from "./CurrentProjects";
import Hero from "./Hero";

const Home = () => {
  // const completedProjects = completedProjectData;
  const [currentProjects, setCurrentProjects] = useState(
    currentProjectData || []
  );

  const findHomeHandler = ({ location, type }) => {
    if (location && type) {
      const tempProjects = currentProjectData.filter(
        (project) =>
          project.type.some(
            (ele) => ele.toLocaleLowerCase() === type.toLocaleLowerCase()
          ) && project.location.toLowerCase() === location.toLowerCase()
      );
      setCurrentProjects(tempProjects);
    } else if (location) {
      const tempProjects = currentProjectData.filter(
        (project) => project.location.toLowerCase() === location.toLowerCase()
      );
      console.log(tempProjects);
      setCurrentProjects(tempProjects);
    } else if (type) {
      const tempProjects = currentProjectData.filter((project) => {
        return project.type.some(
          (ele) => ele.toLocaleLowerCase() === type.toLocaleLowerCase()
        );
      });
      setCurrentProjects(tempProjects);
    }
  };

  return (
    <Layout className="text-5xl">
      <Hero onClick={findHomeHandler} />
      <CurrentProjects projects={currentProjects || []} />
      <div
        className="mt-14 w-full h-[60vh] bg-no-repeat bg-center bg-cover bg-scroll"
        style={{
          backgroundImage: "url('/images/background/bg-one.jpeg')",
        }}
      ></div>
      {/* <CompletedProjects projects={completedProjects || []} /> */}
      <TalkUs />
    </Layout>
  );
};

export default Home;
