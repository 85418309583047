const allUnits = [
  {
    projectId: 1,
    title: "Three Bedroom Units",
    unit: "1 - 6",
    bedRoom: 3,
    bathRoom: 3,
    sofa: 1,
    car: 1,
    images: {
      bedroomImage: {
        type: "Bedroom",
        image: "https://i.ibb.co/h1DVpW2/three-unit-bedroom.jpg",
      },
      bathroomImage: {
        type: "Bathroom",
        image: "https://i.ibb.co/2541Lyq/three-unit-bathroom.jpg",
      },
    },
    unitsHeader: [
      "Unit",
      "Ground floor",
      "First Floor",
      "Second Floor",
      "Total Floor",
    ],
    unitsBody: [
      {
        unit: 1,
        groundFloor: 47.1,
        firstFloor: 44.9,
        secondFloor: 48.7,
        totalFloor: 149.8,
      },
      {
        unit: 2,
        groundFloor: 49.0,
        firstFloor: 47.0,
        secondFloor: 50.0,
        totalFloor: 155.0,
      },
      {
        unit: 3,
        groundFloor: 48.6,
        firstFloor: 46.0,
        secondFloor: 50.0,
        totalFloor: 153.6,
      },
      {
        unit: 4,
        groundFloor: 49.0,
        firstFloor: 47.0,
        secondFloor: 50.5,
        totalFloor: 155.5,
      },
      {
        unit: 5,
        groundFloor: 49.0,
        firstFloor: 47.0,
        secondFloor: 50.5,
        totalFloor: 155.5,
      },
      {
        unit: 6,
        groundFloor: 48.6,
        firstFloor: 46.6,
        secondFloor: 50.5,
        totalFloor: 154.7,
      },
    ],
  },
  {
    projectId: 1,
    title: "One Bedroom Units",
    unit: "7 - 8",
    bedRoom: 1,
    bathRoom: 1,
    sofa: 1,
    car: 1,
    images: {
      bedroomImage: {
        type: "Bedroom",
        image: "https://i.ibb.co/QYzrYG4/one-unit-bedroom.jpg",
      },
      bathroomImage: {
        type: "Bathroom",
        image: "https://i.ibb.co/4tWNJsH/one-unit-bathroom.jpg",
      },
    },
    unitsHeader: ["Unit", "Floor Area", "Deck Area", "Total"],
    unitsBody: [
      {
        unit: 6,
        floorArea: 45.7,
        deckArea: "-",
        totalFloor: 45.7,
      },
      {
        unit: 7,
        floorArea: 54.0,
        deckArea: 6.3,
        totalFloor: 60.3,
      },
    ],
  },
  {
    projectId: 2,
    title: "Three Bedroom Units",
    unit: "1 - 5",
    bedRoom: 3,
    bathRoom: 3,
    sofa: 1,
    car: 1,
    images: {
      bedroomImage: {
        type: "Bedroom",
        image: "https://i.ibb.co/pjVsdYt/three-unit-bedroom.jpg",
      },
      bathroomImage: {
        type: "Bathroom",
        image: "https://i.ibb.co/SXJNm94/three-unit-bathroom.jpg",
      },
    },
    unitsHeader: ["Unit", "Ground floor", "First Floor", "Total Floor"],
    unitsBody: [
      {
        unit: 1,
        groundFloor: 56.6,
        firstFloor: 56.6,
        totalFloor: 113.2,
      },
      {
        unit: 2,
        groundFloor: 58.6,
        firstFloor: 58.6,
        totalFloor: 117.2,
      },
      {
        unit: 3,
        groundFloor: 58.6,
        firstFloor: 58.6,
        totalFloor: 117.2,
      },
      {
        unit: 4,
        groundFloor: 57.8,
        firstFloor: 58.6,
        totalFloor: 116.4,
      },
      {
        unit: 5,
        groundFloor: 58.6,
        firstFloor: 58.6,
        totalFloor: 117.2,
      },
    ],
  },
  {
    projectId: 2,
    title: "One Bedroom Units",
    unit: "6 - 7",
    bedRoom: 1,
    bathRoom: 1,
    sofa: 1,
    car: 1,
    images: {
      bedroomImage: {
        type: "Bedroom",
        image: "https://i.ibb.co/QvQxTDR/one-unit-bedroom.jpg",
      },
      bathroomImage: {
        type: "Bathroom",
        image: "https://i.ibb.co/hLFZm4B/one-unit-bathroom.jpg",
      },
    },
    unitsHeader: ["Unit", "Ground floor", "First Floor", "Total Floor"],
    unitsBody: [
      {
        unit: 6,
        groundFloor: 39.5,
        firstFloor: "-",
        totalFloor: 39.5,
      },
      {
        unit: 7,
        groundFloor: "-",
        firstFloor: 52.2,
        totalFloor: 52.2,
      },
    ],
  },
];

export default allUnits;
