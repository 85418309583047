import React from "react";
import CallUsBtn from "../../components/button/CallUsBtn";
import EnquireBtn from "../../components/button/EnquireBtn";

const PropertyHeader = ({ address }) => {
  const { roadNo, suburb, city } = address || {};
  return (
    <div className="wrapper">
      <div className="py-8 font-poppins lg:flex justify-between grid grid-cols-1 ">
        <div className="mt-4">
          <h3 className="text-[36px] font-poppins">
            {roadNo}, {suburb}
          </h3>
          <div className="flex gap-x-3 xl:ml-2">
            <h3>{city}</h3>
            <span>|</span>
            <h3>Available</h3>
          </div>
        </div>
        <div className="grid grid-cols-2  gap-x-2  mt-4 items-center">
          <div className="col-span-1 w-full">
            <CallUsBtn />
          </div>
          <div className="col-span-1 w-full">
            <EnquireBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyHeader;
