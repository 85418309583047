import React from "react";

const Description = ({ description = [] }) => {
  return (
    description?.length > 0 &&
    description.map((des) => (
      <p
        key={des}
        className="mt-5 text-[18px] text-gray-600 font-poppins font-light"
      >
        {des}
      </p>
    ))
  );
};

export default Description;
